@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  scroll-behavior: smooth;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #023e8a;
  border-radius: 8px;
}

* {
  font-family: "Poppins", sans-serif;
}

html {
  background-color: #000000;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #eff2fa;
}

@layer utilities {
  .wrapper {
    @apply max-w-7xl mx-auto px-4;
  }
  .heading-1 {
    @apply font-raleway text-[2.2rem] sm:text-[2.5rem] md:text-7xl font-bold leading-tight;
  }
  .heading-2 {
    @apply font-raleway text-4xl md:text-5xl font-semibold leading-tight;
  }
  .desc {
    @apply font-inter font-light;
  }
  .active-link {
    @apply !text-primary font-semibold;
  }
  .primary-btn {
    @apply text-white hover:text-primary cursor-pointer font-light border border-primary  tracking-wide bg-primary hover:bg-transparent text-sm hover:shadow-black hover:-translate-y-1  shadow-transparent rounded-[.3rem] px-4 py-3 min-w-[7rem] flex justify-center text-center transition-all duration-300;
  }
  .secondary-btn {
    @apply text-primary hover:text-white cursor-pointer font-light tracking-wide bg-transparent border border-primary hover:bg-primary text-sm  hover:-translate-y-1 shadow-2xl shadow-transparent rounded-[.3rem] px-4 py-3 min-w-[7rem] flex justify-center text-center transition-all duration-300;
  }
  .tertiary-btn {
    @apply text-white cursor-pointer font-light tracking-wide bg-primary border border-primary hover:bg-black text-sm hover:shadow-primary hover:-translate-y-1 shadow-2xl shadow-transparent rounded-[.3rem] px-4 py-3 min-w-[7rem] flex justify-center text-center transition-all duration-300;
  }
  .btn-styles {
    @apply hover:shadow-white cursor-pointer tracking-wide hover:-translate-y-1 shadow-2xl shadow-transparent rounded-full px-4 py-3 min-w-[7rem] flex justify-center text-center transition-all duration-300 font-light text-sm;
  }
  .rounded-text-box {
    @apply px-4 py-3 rounded-full font-raleway min-w-[10rem] flex justify-center border text-[.9rem] w-fit font-bold;
  }
  .gradient-rounded-text-box {
    @apply px-4 py-3 rounded-full font-raleway min-w-[10rem] flex justify-center border text-[.9rem] w-fit bg-gradient-to-r from-[#7186FF_30%] to-primary font-bold border-primary bg-clip-text text-transparent;
  }
  .link {
    @apply cursor-pointer text-black hover:text-primary transition-colors duration-200;
  }
}

/* menu offcanvas styles */
.EZDrawer .EZDrawer__container {
  background: #000000 !important;
  width: 100vw !important;
  min-height: 100vh !important;
  overflow-y: scroll;
  z-index: 20;
}

/* banner video */
#banner video {
  object-fit: cover;
}

/* loading spinner styles */
.spinner-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.726);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent #007acc #007acc;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.react-player video {
  object-fit: fill !important;
}

@layer components {
  .gradient-border {
    @apply border-2 border-transparent;
    border-image: linear-gradient(
      90deg,
      rgba(40, 69, 238, 0.568) 0%,
      rgba(78, 78, 82, 0.414) 100%
    );
    border-image-slice: 1;
  }
}
